<template>
  <v-container class="px-0">
    <v-row>
      <v-col>
        <SpendSimulationMetrics></SpendSimulationMetrics>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SpendSimulationTable></SpendSimulationTable>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" sm="12">
        <v-card outlined class="pa-4">
          <v-card-title class="font-weight-bold">SPEND</v-card-title>
          <stack-bar-chart
            ref="spendChart"
            :series="series_spend"
            :categories="categories"
          >
          </stack-bar-chart>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-card outlined class="pa-4">
          <v-card-title class="font-weight-bold">IMPACT</v-card-title>
          <stack-bar-chart
            ref="impactChart"
            :series="series_impact"
            :categories="categories"
          >
          </stack-bar-chart>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" sm="12">
        <v-card outlined class="pa-4">
          <v-card-title class="font-weight-bold">REVENUE</v-card-title>
          <stack-bar-chart
            ref="revenueChart"
            :series="series_revenue"
            :categories="categories"
          >
          </stack-bar-chart>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-card outlined class="pa-4">
          <v-card-title class="font-weight-bold">ROI(%)</v-card-title>
          <stack-bar-chart
            ref="roiChart"
            chartType ="line"
            :series="series_roi"
            :categories="categories"
            :isStacked = false
          >
          </stack-bar-chart>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SpendSimulation",
  components: {
    SpendSimulationMetrics: () =>
      import("@/views/results/spend-simulation/SpendSimulationMetrics"),
    SpendSimulationTable: () =>
      import("@/views/results/spend-simulation/SpendSimulationTable"),
    StackBarChart: () => import("@/components/chart/ApexChart/StackBarChart"),
  },
  data: () => ({
    panel: [],
    categories: ["Current", "Optimization", "Simulation"],
    series_spend: [],
    series_impact: [],
    series_revenue: [],
    series_roi: [],
  }),
  methods: {
    update_chart() {
      console.log("update chart");
      let roiMetrics = this.$store.state.results.spend_simulation_metrics.ROI;
      let rawData =
        this.$store.state.results.spend_simulation_simulated_response;

      (this.series_spend = []),
        (this.series_impact = []),
        (this.series_revenue = []),
        this.series_roi = []


      rawData.forEach((tacticItem, index) => {
        let {
          tactic,
          current_budget,
          current_impact,
          current_impact_revenue,
          current_roi,
          optimized_budget,
          optimized_impact,
          optimized_impact_revenue,
          optimized_roi,
          simulated_budget,
          simulated_impact,
          simulated_revenue,
          simulated_roi,
        } = tacticItem;

        this.series_spend.push({
          name: tactic,
          data: [],
        });
        this.series_impact.push({
          name: tactic,
          data: [],
        });
        this.series_revenue.push({
          name: tactic,
          data: [],
        });
        this.series_roi.push({
          name: tactic,
          type: 'column',
          data: [],
        });

        this.series_spend[index].data.push(current_budget);
        this.series_spend[index].data.push(optimized_budget);
        this.series_spend[index].data.push(simulated_budget);

        this.series_impact[index].data.push(current_impact);
        this.series_impact[index].data.push(optimized_impact);
        this.series_impact[index].data.push(simulated_impact);

        this.series_revenue[index].data.push(current_impact_revenue);
        this.series_revenue[index].data.push(optimized_impact_revenue);
        this.series_revenue[index].data.push(simulated_revenue);

        this.series_roi[index].data.push(current_roi * 100);
        this.series_roi[index].data.push(optimized_roi * 100);
        this.series_roi[index].data.push(simulated_roi * 100);

      });

      this.series_roi.push(
          {
            name: "All",
            type: "line",
            data: [roiMetrics.current * 100,roiMetrics.optimized * 100,roiMetrics.simulated * 100],
          },
        );
    },
    set_spend_simulation_chart() {
      console.log("SIMULATION");
      let roiMetrics = this.$store.state.results.spend_simulation_metrics.ROI;
      let rawData =
        this.$store.state.results.spend_simulation_simulated_response;

      rawData.forEach((tacticItem, index) => {
        let {
          tactic,
          current_budget,
          current_impact,
          current_impact_revenue,
          current_roi,
          optimized_budget,
          optimized_impact,
          optimized_impact_revenue,
          optimized_roi,
          simulated_budget,
          simulated_impact,
          simulated_revenue,
          simulated_roi,
        } = tacticItem;

        this.series_spend.push({
          name: tactic,
          data: [],
        });
        this.series_impact.push({
          name: tactic,
          data: [],
        });
        this.series_revenue.push({
          name: tactic,
          data: [],
        });
        this.series_roi.push({
          name: tactic,
          type: 'column',
          data: [],
        });
    
        this.series_spend[index].data.push(current_budget);
        this.series_spend[index].data.push(optimized_budget);
        this.series_spend[index].data.push(simulated_budget);

        this.series_impact[index].data.push(current_impact);
        this.series_impact[index].data.push(optimized_impact);
        this.series_impact[index].data.push(simulated_impact);

        this.series_revenue[index].data.push(current_impact_revenue);
        this.series_revenue[index].data.push(optimized_impact_revenue);
        this.series_revenue[index].data.push(simulated_revenue);

        this.series_roi[index].data.push(current_roi * 100);
        this.series_roi[index].data.push(optimized_roi * 100);
        this.series_roi[index].data.push(simulated_roi * 100);

      });
      this.series_roi.push(
          {
            name: "All",
            type: "line",
            data: [roiMetrics.current * 100,roiMetrics.optimized * 100,roiMetrics.simulated * 100],
          },
        );
    },
    
  },
  computed: {
    spend_simulation_simulated_response() {
      return this.$store.state.results.spend_simulation_simulated_response;
    },
    chartData() {
      return this.$store.getters.summaryBarChartData;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  watch: {
    spend_simulation_simulated_response: {
      handler: function () {
        this.update_chart();
      },
    },
  },
  created() {
    this.$store.commit("SET_SPEND_SIMULATION");
    this.set_spend_simulation_chart();
  },
};
</script>

<style scoped></style>